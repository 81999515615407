import { z } from 'zod';

const webBlockImageSchema = z.object({
  id: z.number(),
  title: z.string().nullable(),
  description: z.string().nullable(),
  uri: z.string(),
  originalUri: z.string()
});

const webBlockAttachmentSchema = z.object({
  id: z.number(),
  title: z.string().nullable(),
  mimeType: z.string(),
  fileSize: z.number(),
  isSmartCard: z.boolean(),
  extension: z.string(),
  originalFileName: z.string().nullable(),
  uri: z.string()
});

const webBlockVideoSchema = z.object({
  id: z.number(),
  title: z.string().nullable(),
  description: z.string().nullable(),
  duration: z.number(),
  uri: z.string(),
  image: webBlockImageSchema
});

const webBaseBlockSchema = z.object({
  id: z.number(),
  chapterId: z.number()
});

const webAttachmentBlockSchema = webBaseBlockSchema.extend({
  type: z.literal('attachment'),
  attachment: webBlockAttachmentSchema
});

const webImageBlockSchema = webBaseBlockSchema.extend({
  type: z.literal('image'),
  image: webBlockImageSchema
});

const webVideoBlockSchema = webBaseBlockSchema.extend({
  type: z.literal('video'),
  video: webBlockVideoSchema
});

const webTextBlockSchema = webBaseBlockSchema.extend({
  type: z.literal('text'),
  text: z.string().nullable()
});

export const webBlockSchema = z.discriminatedUnion('type', [
  webAttachmentBlockSchema,
  webImageBlockSchema,
  webVideoBlockSchema,
  webTextBlockSchema
]);

export type WebBlockAttachment = z.infer<typeof webBlockAttachmentSchema>;
export type WebBlockImage = z.infer<typeof webBlockImageSchema>;
export type WebBlockVideo = z.infer<typeof webBlockVideoSchema>;

export type WebAttachmentBlock = z.infer<typeof webAttachmentBlockSchema>;
export type WebImageBlock = z.infer<typeof webImageBlockSchema>;
export type WebVideoBlock = z.infer<typeof webVideoBlockSchema>;
export type WebTextBlock = z.infer<typeof webTextBlockSchema>;

export type WebBlock = z.infer<typeof webBlockSchema>;
