import { z } from 'zod';

export const webCitationSchema = z.object({
  id: z.number(),
  citation: z.string(),
  abstractXhtml: z.string().nullable(),
  externalUri: z.string().nullable()
});

export type WebCitation = z.infer<typeof webCitationSchema>;
