import { match } from 'ts-pattern';
import { z } from 'zod';
import {
  WebAttachmentBlock,
  WebImageBlock,
  WebTextBlock,
  WebVideoBlock
} from '../schema/webEpisode/webChapterBlock';
import { WebChapter, webChapterSchema } from '../schema/webEpisode/webChapter';

const legacyTextBlockSchema = z.object({
  displayOrder: z.number(),
  bodyText: z.string(),
  type: z.literal('text')
});

export const legacyAttachmentBlockSchema = z.object({
  displayOrder: z.number(),
  title: z.string(),
  mimeType: z.string(),
  fileSize: z.number(),
  extension: z.string(),
  folder: z.string(),
  uri: z.string(),
  isSmartCard: z.boolean(),
  attachmentId: z.number(),
  type: z.literal('attachment')
});

const legacyMediaBlockSchema = z.object({
  displayOrder: z.number(),
  title: z.string().nullable(),
  description: z.string(),
  imageUris: z.array(z.string()),
  videoKey: z.string(),
  type: z.literal('media')
});

const legacyImageBlockSchema = z.object({
  displayOrder: z.number(),
  title: z.string(),
  description: z.string().nullable(),
  imageUris: z.array(z.string()),
  type: z.literal('image')
});

const legacyBlocksSchema = z.object({
  citation: z.string(),
  text: z.array(legacyTextBlockSchema),
  attachment: z.array(legacyAttachmentBlockSchema),
  media: z.array(legacyMediaBlockSchema),
  image: z.array(legacyImageBlockSchema)
});

const legacyWebChapterSchema = webChapterSchema
  .omit({ citations: true })
  .extend({ blocks: legacyBlocksSchema });

// TODO: remove this module once all episodes are republished with new blocks
// format on Firestore
export function migrateLegacyBlocksForChapter(chapter: unknown) {
  const parseResult = webChapterSchema.safeParse(chapter);
  if (parseResult.success) {
    return parseResult.data;
  }

  const parsedChapter = legacyWebChapterSchema.parse(chapter);
  const { blocks } = parsedChapter;

  const citations = [
    {
      id: 1,
      citation: '',
      abstractXhtml: blocks.citation,
      externalUri: null
    }
  ];

  const allBlocks = [
    ...parsedChapter.blocks.attachment,
    ...parsedChapter.blocks.image,
    ...parsedChapter.blocks.media,
    ...parsedChapter.blocks.text
  ];

  allBlocks.sort((a, b) => a.displayOrder - b.displayOrder);

  const newBlocks = allBlocks.map((oldBlock, index) =>
    match(oldBlock)
      .with(
        { type: 'attachment' },
        matchedBlock =>
          ({
            id: index,
            chapterId: parsedChapter.id,
            type: matchedBlock.type,
            attachment: {
              id: matchedBlock.attachmentId,
              title: matchedBlock.title,
              mimeType: matchedBlock.mimeType,
              fileSize: matchedBlock.fileSize,
              originalFileName: null,
              extension: matchedBlock.extension.toLowerCase(),
              isSmartCard: matchedBlock.isSmartCard,
              uri: matchedBlock.uri
            }
          } satisfies WebAttachmentBlock)
      )
      .with({ type: 'image' }, matchedBlock => {
        const matches = matchedBlock.imageUris[0].match(/(\d+)\.[^.]+$/);
        const imageId =
          matches !== null && matches[1] !== null ? Number(matches[1]) : 0;

        return {
          id: index,
          chapterId: parsedChapter.id,
          type: matchedBlock.type,
          image: {
            id: imageId,
            title: matchedBlock.title,
            description: matchedBlock.description,
            uri: matchedBlock.imageUris[1],
            originalUri: matchedBlock.imageUris[0]
          }
        } satisfies WebImageBlock;
      })
      .with({ type: 'media' }, matchedBlock => {
        const matches = matchedBlock.imageUris[0].match(/(\d+)\.[^.]+$/);
        const imageId =
          matches !== null && matches[1] !== null ? Number(matches[1]) : 0;

        return {
          id: index,
          chapterId: parsedChapter.id,
          type: 'video',
          video: {
            id: index,
            uri: `media/${matchedBlock.videoKey}/hld/index.m3u8`,
            title: matchedBlock.title,
            description: matchedBlock.description,
            duration: 0,
            image: {
              id: imageId,
              title: null,
              description: null,
              // Legacy block format did not prefix video thumbnails with `images`
              uri: `images/${matchedBlock.imageUris[1]}`,
              originalUri: `images/${matchedBlock.imageUris[0]}`
            }
          }
        } satisfies WebVideoBlock;
      })
      .with(
        { type: 'text' },
        matchedBlock =>
          ({
            id: index,
            chapterId: parsedChapter.id,
            type: matchedBlock.type,
            text: matchedBlock.bodyText
          } satisfies WebTextBlock)
      )
      .run()
  );

  return {
    ...parsedChapter,
    citations,
    blocks: newBlocks
  } satisfies WebChapter;
}
