import React from 'react';
import classnames from 'classnames';
import { osName } from 'react-device-detect';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';
import { WebAttachmentBlock } from '../../schema/webEpisode/webChapterBlock';
import { ChapterDownloadItem } from './ChapterDownloadItem';

import './ChapterSmartCards.scss';

interface ChapterSmartCardsProps {
  smartCards: WebAttachmentBlock[];
  episodeType: EpisodeTypeShortname;
}

export function ChapterSmartCards({
  smartCards,
  episodeType
}: ChapterSmartCardsProps) {
  const uri = `${process.env.REACT_APP_SMART_CARD_URI}`;
  return (
    <div
      className={classnames(
        'chapter-smart-cards',
        `chapter-smart-cards--${episodeType}`
      )}
    >
      {smartCards.map(smartCard =>
        osName === 'iOS' || osName === 'Android' ? (
          <ChapterDownloadItem
            attachment={smartCard.attachment}
            episodeType={episodeType}
            canDownload
            key={smartCard.id}
          />
        ) : (
          <embed
            className="chapter-smart-cards__smart-card-pdf"
            title={smartCard.attachment.title ?? undefined}
            src={`${uri}${smartCard.attachment.id}`}
            key={smartCard.id}
            aria-label={`${smartCard.attachment.title} PDF`}
          />
        )
      )}
    </div>
  );
}
