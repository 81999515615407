import { match } from 'ts-pattern';

export function getCitationLinkText(uri: string) {
  const url = (() => {
    try {
      return new URL(uri);
    } catch (error) {
      return null;
    }
  })();

  return match(url)
    .with({ hostname: 'www.ncbi.nlm.nih.gov' }, () => 'View Article on PubMed')
    .with(
      { hostname: 'www.annemergmed.com' },
      () => 'View Article on Annals of Emergency Medicine'
    )
    .with({ hostname: 'www.nejm.org' }, () => 'View Article on the NEJM')
    .with(
      { hostname: 'onlinelibrary.wiley.com' },
      () => 'View Article on Wiley Online Library'
    )
    .with({ hostname: 'www.bmj.com' }, () => 'View Article on the BMJ')
    .otherwise(() => 'View Full Article');
}
