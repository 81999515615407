import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Helmet } from 'react-helmet';
import Loader from '../Loader';
import { ChapterTab } from '../../lib/types';
import { WebEpisode } from '../../schema/webEpisode/webEpisode';
import {
  ChapterCommentStatus,
  WebChapter
} from '../../schema/webEpisode/webChapter';
import { ESVideoPlaylist } from '../../schema/playlist/ESVideoPlaylist';
import ChapterPageFooter from '../ChapterPage/ChapterPageFooter';
import ChapterSummary from '../ChapterPage/ChapterSummary';
import { hasPaidAndActiveSubscription } from '../../lib/hasPaidAndActiveSubscription';
import { HDHeaderBar } from '../HDNav/HDHeaderBar';
import { useLoadVideoPlaylistQuery } from '../../store/videoPlaylists';
import HDEpisodePlayerGroup from './HDEpisodePlayerGroup';
import { ChapterSmartCards } from '../ChapterPage/ChapterSmartCards';
import Conversation from '../Conversation/Conversation';
import { accountSlice } from '../../store/account';
import { useDispatch, useSelector } from '../../lib/hooks';
import { getSmartCards } from '../../lib/getSmartCards';
import useScrollToHash from '../../hooks/useScrollToHash';
import { commentsSlice, loadComments } from '../../store/comments';
import { ChapterPageBreadcrumb } from '../ChapterPage/ChapterPageBreadcrumb';
import getBreadcrumbLinks from '../../lib/getBreadcrumbLinks';
import ChapterTabs from '../ChapterPage/ChapterTabs';
import { HDPlaylistItems } from '../HDPlaylistPage/HDPlaylistItems';
import { RelatedPlaylist } from '../ChapterPage/RelatedPlaylist';
import { CmeCreditsTab } from '../CmeCreditsTab/CmeCreditsTab';
import { ChapterCme } from '../Cme/ChapterCme';
import { FrontMatterModal } from '../Cme/FrontMatterModal';
import { FeedbackBar } from '../FeedbackBar';
import { useCmeCredit } from '../../hooks/useCmeCredit';
import { getAccountType } from '../../lib/getAccountType';
import config from '../../config';
import { getPageSpecialty } from '../../lib/getPageSpecialty';
import { getChapterHelmetTitle } from '../../lib/getChapterHelmetTitle';
import { useRelatedContent } from '../../hooks/useRelatedContent';
import { cmeEpisodesCreditSlice } from '../../store/cmeEpisodesCredit';
import { cmeFacultiesSlice } from '../../store/cmeFaculties';
import { FeedbackFreeTrialStatus } from '../FeedbackFreeTrialStatus';

import './HDChapterPage.scss';

const { mediaQueries } = config;

interface HDChapterPageComponentProps {
  episode: WebEpisode;
  chapter: WebChapter;
  isDesktop: boolean;
  playlistShortnames: string[];
  isUrgentCare: boolean;
}

function getInitialTab(hash: string, smartCardsCount: number) {
  if (hash.endsWith('-comment')) {
    return 'Conversation';
  }
  if (smartCardsCount > 0) {
    return 'Smart Cards';
  }
  return 'Summary';
}

export const HDChapterPage = ({
  episode,
  chapter,
  isDesktop,
  playlistShortnames,
  isUrgentCare
}: HDChapterPageComponentProps) => {
  const { modernizeCmeCredits } = useFlags();
  const dispatch = useDispatch();
  const comments = useSelector(state => state[commentsSlice.name].comments);
  const accountState = useSelector(state => state[accountSlice.name]);
  const { pathname } = useLocation();
  const isExtraChapter = chapter.extras.length > 0;
  const isSignedIn = accountState.account !== null;
  const isPaidAndActive = hasPaidAndActiveSubscription(accountState);
  const isTrial = getAccountType(accountState) === 'trial';
  const smartCards = isPaidAndActive ? getSmartCards(episode) : [];
  const isFreeAccount = getAccountType(accountState) === 'free';
  const { hash } = window.location;
  const [selectedTab, setSelectedTab] = useState<ChapterTab>(
    getInitialTab(hash, smartCards.length)
  );
  const helmetTitle = getChapterHelmetTitle(episode, chapter);

  // Current CME credit API
  const {
    cmeCredit,
    shouldShowFrontMatter,
    shouldShowCmeLinks,
    handleAttestCme,
    hasCmeError
  } = useCmeCredit(
    episode,
    isSignedIn,
    getAccountType(accountState),
    isPaidAndActive
  );
  const cmeCreditsHours =
    !isExtraChapter && cmeCredit ? Number(cmeCredit.credit.hours) : 0;

  // New CME credit API
  const chapterFaculty =
    useSelector(
      state => state[cmeFacultiesSlice.name].faculties?.[chapter.id]
    ) ?? [];
  const newCmeCredit = useSelector(
    state => state[cmeEpisodesCreditSlice.name].credits?.[episode.id]
  );
  const newCmeCreditsHours =
    !isExtraChapter && newCmeCredit !== undefined
      ? newCmeCredit.overallCreditHours
      : 0;

  const media = {
    lg: useMediaQuery({ query: mediaQueries.lg })
  };

  const currentPlaylist = playlistShortnames[playlistShortnames.length - 1];
  const {
    relatedContent,
    relatedPlaylist,
    isLoading: isRelatedContentLoading
  } = useRelatedContent(chapter.id, currentPlaylist);

  useScrollToHash();

  useEffect(() => {
    dispatch(loadComments(chapter.id));
  }, [chapter.id]);

  const playlists = playlistShortnames
    .map(shortname => {
      const { data: playlist } = useLoadVideoPlaylistQuery(shortname);
      return playlist;
    })
    .filter((playlist): playlist is ESVideoPlaylist => playlist !== undefined);

  return (
    <div className="hd-chapter-page">
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      {!modernizeCmeCredits && (
        <FrontMatterModal
          isOpen={shouldShowFrontMatter}
          handleAttestCme={handleAttestCme}
          episodeId={episode.id}
          isHDEpisode
          isUrgentCare={isUrgentCare}
          hasCmeError={hasCmeError}
        />
      )}
      <div className="hd-chapter-page__top-container--background">
        <div className="hd-chapter-page__top-container">
          <HDHeaderBar
            displayHDLogo
            isUrgentCare={isUrgentCare}
            isChapterPage
          />
          <FeedbackFreeTrialStatus
            className="hd-chapter-page__trial-feedback"
            account={accountState?.account}
          />
          <ChapterPageBreadcrumb
            indexLinks={getBreadcrumbLinks(
              playlists,
              episode,
              pathname,
              episode.type
            )}
            episodeType={episode.type}
          />
          <div className="hd-chapter-page__main-content">
            <HDEpisodePlayerGroup
              episode={episode}
              chapter={chapter}
              isDesktop={isDesktop}
              playlist={
                playlists.length > 0 ? playlists[playlists.length - 1] : null
              }
              isPaidAndActive={isPaidAndActive}
              isSignedIn={isSignedIn}
              isUrgentCare={isUrgentCare}
              accountId={accountState.account?.id}
            />
          </div>
          <ChapterTabs
            episodeType={episode.type}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            hasCrunchyQs={false}
            chapterComments={comments || []}
            smartCardsCount={smartCards.length}
            isDesktop={media.lg}
            cmeCreditsHours={
              modernizeCmeCredits ? newCmeCreditsHours : cmeCreditsHours
            }
          />
        </div>
      </div>
      <div className="hd-chapter-page__bottom-container">
        <div className="hd-chapter-page__tab-content">
          {isRelatedContentLoading ? (
            <Loader />
          ) : (
            <div className="hd-chapter-page__tab-content-container">
              {selectedTab === 'Summary' && (
                <ChapterSummary
                  episodeType={episode.type}
                  relatedEpisodes={episode.relatedEpisodes}
                  chapter={chapter}
                  isPaidAndActive={isPaidAndActive}
                  isSignedIn={isSignedIn}
                  isDesktop={isDesktop}
                  relatedPlaylist={relatedPlaylist}
                  isUrgentCare={isUrgentCare}
                />
              )}
              {smartCards.length > 0 && selectedTab === 'Smart Cards' && (
                <ChapterSmartCards
                  smartCards={smartCards}
                  episodeType={episode.type}
                />
              )}
              {selectedTab === 'Conversation' &&
                chapter.commentStatus !== ChapterCommentStatus.CLOSED && (
                  <Conversation
                    episodeId={episode.id}
                    chapterId={chapter.id}
                    chapterCommentStatus={chapter.commentStatus}
                    comments={comments || []}
                    isLoading={false}
                    episodeType={episode.type}
                    isPaidAndActive={isPaidAndActive}
                    isSignedIn={isSignedIn}
                    isFreeAccount={isFreeAccount}
                    isTrial={isTrial}
                  />
                )}
              {!modernizeCmeCredits &&
                selectedTab === 'CME Credits' &&
                !isExtraChapter &&
                (hasCmeError ? (
                  <FeedbackBar
                    variant="negative"
                    message="There seems to be an error loading data."
                    actionText="Try refreshing again."
                    onClick={() => window.location.reload()}
                  />
                ) : (
                  cmeCredit !== undefined && (
                    <ChapterCme
                      cmeCredit={cmeCredit}
                      episodeShortname={episode.shortname}
                      isPaidAndActive={isPaidAndActive}
                      isSignedIn={isSignedIn}
                      episodeType={episode.type}
                      shouldShowCmeLinks={shouldShowCmeLinks}
                    />
                  )
                ))}
              {modernizeCmeCredits &&
                selectedTab === 'CME Credits' &&
                !isExtraChapter &&
                newCmeCredit !== undefined && (
                  <CmeCreditsTab
                    cmeFaculty={chapterFaculty}
                    isPaidAndActive={isPaidAndActive}
                    isSignedIn={isSignedIn}
                    episodeType={episode.type}
                    cmeEpisodeCredit={newCmeCredit}
                    isTrial={isTrial}
                  />
                )}
              {selectedTab !== 'Summary' && selectedTab !== 'Conversation' && (
                <div className="hd-chapter-page__tab-content-right-panel">
                  {relatedPlaylist && relatedPlaylist.length > 0 && (
                    <RelatedPlaylist
                      relatedPlaylist={relatedPlaylist}
                      isUrgentCare={isUrgentCare}
                    />
                  )}
                </div>
              )}
            </div>
          )}
          {relatedContent &&
            relatedContent.length > 0 &&
            !isRelatedContentLoading && (
              <div className="hd-chapter-page__suggestions">
                <span className="hd-chapter-page__suggestions-title">
                  You may also like
                </span>
                <HDPlaylistItems
                  items={relatedContent}
                  className="hd-chapter-page__suggestions-list"
                  trimmedUrl=""
                  isUrgentCare={isUrgentCare}
                  isSuggestionList
                  pageSpecialty={getPageSpecialty(episode.type)}
                />
              </div>
            )}
        </div>
      </div>
      {isDesktop && <ChapterPageFooter />}
    </div>
  );
};
