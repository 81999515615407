import React from 'react';
import { WebBlockAttachment } from '../../../schema/webEpisode/webChapterBlock';
import { EpisodeTypeShortname } from '../../../schema/webEpisode/episodeType';
import { ChapterDownloadItem } from '../ChapterDownloadItem';

import './AttachmentBlock.scss';

interface AttachmentBlockProps {
  attachment: WebBlockAttachment;
  episodeType: EpisodeTypeShortname;
  canDownload: boolean;
}

export function AttachmentBlock({
  attachment,
  episodeType,
  canDownload
}: AttachmentBlockProps) {
  return (
    <ChapterDownloadItem
      attachment={attachment}
      canDownload={canDownload}
      episodeType={episodeType}
    />
  );
}
