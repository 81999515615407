import React, { useState, useRef, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import classnames from 'classnames';
import { WebChapter } from '../../schema/webEpisode/webChapter';
import {
  WebBlock,
  WebImageBlock,
  WebVideoBlock
} from '../../schema/webEpisode/webChapterBlock';
import { RelatedEpisode } from '../../schema/webEpisode/relatedEpisode';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';
import formatDuration from '../../lib/formatDuration';
import { getPhpSitePath } from '../../lib/getPhpSitePath';
import config from '../../config';
import { Block } from './Block/Block';
import { Citation } from './Citation/Citation';
import { RelatedMedia } from './RelatedMedia';
import { ReactComponent as DownIcon } from '../../images/icons/icon-arrow--down.svg';
import SubscriptionStatus from './SubscriptionStatus';
import { RelatedPlaylist } from './RelatedPlaylist';
import { RelatedContentPlaylistItem } from '../../schema/playlist/RelatedContentItem';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { getPageSpecialty } from '../../lib/getPageSpecialty';

import './ChapterSummary.scss';

function isImageOrVideo(
  block: WebBlock
): block is WebImageBlock | WebVideoBlock {
  return block.type === 'image' || block.type === 'video';
}

interface ChapterSummaryProps {
  episodeType: EpisodeTypeShortname;
  relatedEpisodes: RelatedEpisode[];
  chapter: WebChapter;
  isPaidAndActive: boolean;
  isSignedIn: boolean;
  isDesktop: boolean;
  relatedPlaylist?: RelatedContentPlaylistItem[];
  isUrgentCare: boolean;
  isSingleChapterEpisode?: boolean;
}

const ChapterSummary = ({
  episodeType,
  relatedEpisodes,
  chapter,
  isPaidAndActive,
  isSignedIn,
  isDesktop,
  relatedPlaylist,
  isUrgentCare,
  isSingleChapterEpisode = false
}: ChapterSummaryProps) => {
  const [isContentCollapsed, setIsContentCollapsed] = useState(false);
  const relatedMedia = chapter.blocks.filter(isImageOrVideo);
  const { cdnUrl } = config;
  const summaryRef = useRef<HTMLDivElement>(null);
  const { modernizeTags, modernizeCmeTable } = useFlags();
  const shouldShowSummaryContent =
    chapter.isFree || (isPaidAndActive && isSignedIn);

  let sortedTags =
    chapter.tags &&
    chapter.tags.length > 0 &&
    [...chapter.tags]
      .sort((a, b) => a.title.localeCompare(b.title))
      .map(tag => ({
        ...tag,
        link: `/search?tag-select=%5B%22${encodeURIComponent(tag.title)}%22%5D`
      }));

  if (modernizeCmeTable && Array.isArray(sortedTags)) {
    sortedTags = sortedTags.filter(tag => tag.hasCme === false);
  }
  const pageSpecialty = getPageSpecialty(episodeType);

  useEffect(() => {
    const summaryNode = summaryRef.current;
    if (!isDesktop && summaryNode && summaryNode.clientHeight > 500) {
      setIsContentCollapsed(true);
    }
  }, []);

  return (
    <div
      className={classnames(
        'chapter-summary',
        `chapter-summary--${episodeType}`,
        { 'chapter-summary--disabled': !shouldShowSummaryContent }
      )}
    >
      <div className="chapter-summary__left-container">
        {shouldShowSummaryContent ? (
          <div
            className={classnames('chapter-summary__contents', {
              'chapter-summary__contents--collapsed':
                !isDesktop && isContentCollapsed
            })}
            ref={summaryRef}
          >
            {chapter.description && (
              <div
                className="chapter-summary__chapter-description"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: chapter.description }} // HTML from EM:RAP admin
              />
            )}
            {chapter.citations.map(citation => (
              <Citation
                key={citation.id}
                episodeType={episodeType}
                citation={citation}
              />
            ))}
            {chapter.blocks.map(block => (
              <Block
                key={block.id}
                chapterBlock={block}
                episodeType={episodeType}
                canDownload={(isSignedIn && isPaidAndActive) || chapter.isFree}
              />
            ))}
            {!isDesktop && isContentCollapsed && (
              <button
                className="chapter-summary__expand-button"
                type="button"
                onClick={() => setIsContentCollapsed(false)}
              >
                CONTINUE READING
                <DownIcon className="chapter-summary__expand-icon" />
              </button>
            )}
          </div>
        ) : (
          <SubscriptionStatus
            tabName="summary"
            isSignedIn={isSignedIn}
            isPaidAndActive={isPaidAndActive}
            episodeType={episodeType}
          />
        )}
        {!modernizeTags &&
          modernizeCmeTable !== undefined &&
          Array.isArray(sortedTags) &&
          sortedTags.length > 0 && (
            <div className="chapter-summary__tags-container">
              <span className="chapter-summary__tags-title">
                {sortedTags.length > 1 ? 'Tags: ' : 'Tag: '}
              </span>
              {sortedTags.map(tag => (
                <a
                  className="chapter-summary__tag"
                  href={getPhpSitePath(tag.link)}
                  key={tag.id}
                  onClick={() =>
                    reportLinkClickedAnalytics({
                      to: tag.link,
                      linkText: tag.title,
                      pageSpecialty,
                      eventSource: 'tag'
                    })
                  }
                >
                  {tag.title}
                </a>
              ))}
            </div>
          )}
      </div>
      <div
        className={classnames('chapter-summary__sidebar', {
          'chapter-summary__sidebar--single-chapter': isSingleChapterEpisode
        })}
      >
        {chapter.corependiumChapters &&
          chapter.corependiumChapters.length > 0 && (
            <div className="chapter-summary__corependium-chapters-container">
              <span className="chapter-summary__corependium-chapters-title">
                CorePendium Chapters
              </span>
              <ul className="chapter-summary__corependium-chapters-links">
                {chapter.corependiumChapters.map(
                  (corependiumChapter, index) => (
                    <li key={`${corependiumChapter.title + index}`}>
                      <a
                        className="chapter-summary__corependium-chapter-link"
                        href={corependiumChapter.uri}
                        onClick={() =>
                          reportLinkClickedAnalytics({
                            to: corependiumChapter.uri,
                            linkText: corependiumChapter.title,
                            pageSpecialty,
                            eventSource: 'corependium chapter'
                          })
                        }
                      >
                        {corependiumChapter.title}
                      </a>
                    </li>
                  )
                )}
              </ul>
            </div>
          )}
        {shouldShowSummaryContent && relatedMedia.length > 0 && (
          <div className="chapter-summary__related-media-container">
            <span className="chapter-summary__related-media-title">
              Related Media
            </span>
            <ul className="chapter-summary__related-media-links">
              {relatedMedia.map(block => (
                <RelatedMedia
                  key={block.id}
                  block={block}
                  episodeType={episodeType}
                />
              ))}
            </ul>
          </div>
        )}
        {relatedEpisodes && relatedEpisodes.length > 0 && (
          <div className="chapter-summary__related-episodes-container">
            <span className="chapter-summary__related-episodes-title">
              Related Episodes
            </span>
            <ul className="chapter-summary__related-episodes-links">
              {relatedEpisodes.slice(0, 5).map((episode, index) => (
                <li key={`${episode.title + index}`}>
                  <a
                    href={getPhpSitePath(`/episode/${episode.shortname}`)}
                    className="chapter-summary__related-episode-link"
                    onClick={() =>
                      reportLinkClickedAnalytics({
                        to: `episode/${episode.shortname}`,
                        linkText: episode.title,
                        pageSpecialty,
                        eventSource: 'related episode'
                      })
                    }
                  >
                    <img
                      className="chapter-summary__related-episode-artwork"
                      alt={episode.title}
                      src={`${cdnUrl}/images/${episode.artworkUri}`}
                    />
                    <div className="chapter-summary__related-episode-details">
                      <span className="chapter-summary__related-episode-title">
                        {episode.title}
                      </span>
                      <span className="chapter-summary__related-episode-chapter-count">
                        {episode.chapterCount} Chapters
                      </span>
                      <span className="chapter-summary__related-episode-duration">
                        {formatDuration(episode.duration)}
                      </span>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
        {relatedPlaylist && relatedPlaylist.length > 0 && (
          <RelatedPlaylist
            relatedPlaylist={relatedPlaylist}
            isUrgentCare={isUrgentCare}
          />
        )}
      </div>
    </div>
  );
};

export default ChapterSummary;
