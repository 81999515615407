import React from 'react';
import ReactPlayer from 'react-player';
import config from '../../../config';
import { reportButtonClickedAnalytics } from '../../../analytics/reportButtonClickedAnalytics';
import { WebBlockVideo } from '../../../schema/webEpisode/webChapterBlock';
import { BlockDetails } from './BlockDetails';

import './VideoBlock.scss';

const { cdnUrl } = config;

interface VideoBlockProps {
  video: WebBlockVideo;
  pageSpecialty?: string;
}

export function VideoBlock({
  video: {
    title,
    description,
    uri,
    image: { uri: imageUri }
  },
  pageSpecialty
}: VideoBlockProps) {
  return (
    <div className="video-block__wrapper">
      <div className="video-bock__player" id={uri}>
        <ReactPlayer
          url={`${cdnUrl}/${uri}`}
          controls
          playing
          width="100%"
          height="100%"
          light={`${cdnUrl}/${imageUri}`}
          onStart={() =>
            reportButtonClickedAnalytics({
              buttonType: 'view video',
              eventSource: 'chapter block',
              pageSpecialty
            })
          }
        />
      </div>
      {(title !== null || description !== null) && (
        <BlockDetails title={title} description={description} />
      )}
    </div>
  );
}
