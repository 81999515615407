import React from 'react';

import './TextBlock.scss';

interface TextBlockProps {
  text: string | null;
}

export function TextBlock({ text }: TextBlockProps) {
  return (
    <div
      className="text-block__wrapper"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: text ?? '' }} // HTML from EM:RAP admin
    />
  );
}
