import React from 'react';
import config from '../../../config';
import { reportLinkClickedAnalytics } from '../../../analytics/reportLinkClickedAnalytics';
import { WebBlockImage } from '../../../schema/webEpisode/webChapterBlock';
import { BlockDetails } from './BlockDetails';

import './ImageBlock.scss';

const { cdnUrl } = config;

interface ImageBlockProps {
  image: WebBlockImage;
  pageSpecialty?: string;
}

export function ImageBlock({
  image: { title, description, uri, originalUri },
  pageSpecialty
}: ImageBlockProps) {
  return (
    <div id={uri} className="image-block__wrapper">
      <a
        href={`${cdnUrl}/${originalUri}`}
        target="_blank"
        rel="noopener noreferrer"
        className="image-block__player"
        onClick={event =>
          reportLinkClickedAnalytics({
            to: `${cdnUrl}/${originalUri}`,
            linkText: event.currentTarget.text,
            eventSource: 'chapter block',
            pageSpecialty
          })
        }
      >
        <img
          src={`${cdnUrl}/${uri}`}
          alt={title ?? description ?? ''}
          className="image-block__image"
        />
        <span className="image-block__button">Full Size Image</span>
      </a>
      {(title !== null || description !== null) && (
        <BlockDetails title={title} description={description} />
      )}
    </div>
  );
}
