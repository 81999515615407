import React from 'react';

import './BlockDetails.scss';

interface BlockDetailsProps {
  title: string | null;
  description: string | null;
}

export function BlockDetails({ title, description }: BlockDetailsProps) {
  return (
    <span className="block-details">
      {title !== null && <span className="block-details__title">{title}</span>}
      {description !== null && (
        <span className="block-details__description">{description}</span>
      )}
    </span>
  );
}
