import React from 'react';
import { match } from 'ts-pattern';
import { WebBlock } from '../../../schema/webEpisode/webChapterBlock';
import { EpisodeTypeShortname } from '../../../schema/webEpisode/episodeType';
import { getPageSpecialty } from '../../../lib/getPageSpecialty';
import { AttachmentBlock } from './AttachmentBlock';
import { ImageBlock } from './ImageBlock';
import { VideoBlock } from './VideoBlock';
import { TextBlock } from './TextBlock';

interface BlockProps {
  chapterBlock: WebBlock;
  episodeType: EpisodeTypeShortname;
  canDownload?: boolean;
}

export function Block({
  chapterBlock,
  episodeType,
  canDownload = false
}: BlockProps) {
  const pageSpecialty = getPageSpecialty(episodeType);

  return (
    <div className={`block block--${episodeType}`}>
      {match(chapterBlock)
        .with({ type: 'attachment' }, block => (
          <AttachmentBlock
            attachment={block.attachment}
            episodeType={episodeType}
            canDownload={canDownload}
          />
        ))
        .with({ type: 'image' }, block => (
          <ImageBlock image={block.image} pageSpecialty={pageSpecialty} />
        ))
        .with({ type: 'video' }, block => (
          <VideoBlock video={block.video} pageSpecialty={pageSpecialty} />
        ))
        .with({ type: 'text' }, block => <TextBlock text={block.text} />)
        .exhaustive()}
    </div>
  );
}
