import {
  WebAttachmentBlock,
  WebBlock
} from '../schema/webEpisode/webChapterBlock';
import { WebEpisode } from '../schema/webEpisode/webEpisode';

function isSmartCard(block: WebBlock): block is WebAttachmentBlock {
  return block.type === 'attachment' && block.attachment.isSmartCard;
}

export function getSmartCards(episode: WebEpisode) {
  return episode.chapters.reduce<WebAttachmentBlock[]>(
    (attachments, { blocks }) => [
      ...attachments,
      ...blocks.filter(isSmartCard)
    ],
    []
  );
}
