import React from 'react';
import { WebCitation } from '../../../schema/webEpisode/webChapterCitation';
import { EpisodeTypeShortname } from '../../../schema/webEpisode/episodeType';
import { getCitationLinkText } from './getCitationLinkText';

import './Citation.scss';

interface CitationProps {
  episodeType: EpisodeTypeShortname;
  citation: WebCitation;
}

export function Citation({
  episodeType,
  citation: { citation, abstractXhtml, externalUri }
}: CitationProps) {
  return (
    <div className={`citation citation--${episodeType}`}>
      <h3 className="citation__citation">{citation}</h3>
      {abstractXhtml !== null && abstractXhtml !== '' && (
        <p
          className="citation__abstract"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: abstractXhtml }}
        />
      )}
      {externalUri !== null && externalUri !== '' && (
        <p>
          <a className="citation__external-uri" href={externalUri}>
            {getCitationLinkText(externalUri)}
          </a>
        </p>
      )}
    </div>
  );
}
