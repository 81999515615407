import React from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';
import {
  WebImageBlock,
  WebVideoBlock
} from '../../schema/webEpisode/webChapterBlock';
import { getPhpSitePath } from '../../lib/getPhpSitePath';
import config from '../../config';
import { ReactComponent as LinkArrowIcon } from '../../images/icons/icon-link-arrow.svg';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { getPageSpecialty } from '../../lib/getPageSpecialty';

import './RelatedMedia.scss';

const { cdnUrl } = config;

interface RelatedMediaProps {
  block: WebImageBlock | WebVideoBlock;
  episodeType: EpisodeTypeShortname;
}

export function RelatedMedia({ block, episodeType }: RelatedMediaProps) {
  const pathname = getPhpSitePath(useLocation().pathname);
  const imageSrc =
    block.type === 'image'
      ? `${cdnUrl}/${block.image.uri}`
      : `${cdnUrl}/${block.video.image.uri}`;

  const uri = block.type === 'image' ? block.image.uri : block.video.uri;
  const title = block.type === 'image' ? block.image.title : block.video.title;
  const description =
    block.type === 'image' ? block.image.description : block.video.description;

  return (
    <li
      className={classnames('related-media', `related-media--${episodeType}`)}
    >
      <a
        // Link was not used in this case, because it doesn't work correctly with a hashed link.
        // For context, see https://github.com/remix-run/react-router/issues/394#issuecomment-220221604
        className="related-media__link"
        href={`${pathname}#${uri}`}
        onClick={event => {
          reportLinkClickedAnalytics({
            to: `${pathname}#${uri}`,
            linkText: event.currentTarget.text,
            eventSource: 'related media',
            pageSpecialty: getPageSpecialty(episodeType)
          });
        }}
      >
        <span className="related-media__link-title">
          {block.type === 'image' ? 'Image' : 'Video'}
          <LinkArrowIcon className="related-media__link-arrow" />
        </span>
        <div className="related-media__image-wrapper">
          <img
            className="related-media__image"
            src={imageSrc}
            alt={title ?? description ?? ''}
          />
        </div>
      </a>
    </li>
  );
}
