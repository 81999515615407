import React, { ReactNode } from 'react';
import { match, P } from 'ts-pattern';
import classnames from 'classnames';
import Icon from '../Icons/Icon';
import { WebBlockAttachment } from '../../schema/webEpisode/webChapterBlock';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';
import formatBytes from '../../lib/formatBytes';
import config from '../../config';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { getPageSpecialty } from '../../lib/getPageSpecialty';

import './ChapterDownloadItem.scss';

const { cdnUrl } = config;

interface ChapterDownloadItemProps {
  attachment: WebBlockAttachment;
  canDownload: boolean;
  episodeType: EpisodeTypeShortname;
}

function getIconName(extension: string) {
  return match(extension.toLowerCase())
    .with(
      P.union('m4a', 'mp4', 'mp3', 'zip', 'png', 'doc', 'html', 'pdf'),
      matchedExtension => matchedExtension
    )
    .with(P.union('jpg', 'jpeg'), () => 'jpg')
    .otherwise(() => 'default-extension');
}

function getFileType(extension: string) {
  return match(extension.toLowerCase())
    .with('html', () => 'Web Page')
    .otherwise(matchedExtension => matchedExtension.toUpperCase());
}

function ChapterDownloadItemWrapper({
  attachment,
  canDownload,
  episodeType,
  children
}: ChapterDownloadItemProps & { children: ReactNode }) {
  if (!canDownload) {
    return (
      <div className="chapter-download-item chapter-download-item--disabled">
        {children}
      </div>
    );
  }

  const episodeColorClassName = `chapter-download-item--${episodeType}`;
  const href = `${cdnUrl}/${attachment.uri}`;

  return (
    <a
      className={classnames('chapter-download-item', episodeColorClassName)}
      href={href}
      onClick={() =>
        reportLinkClickedAnalytics({
          to: href,
          linkText: attachment.title ?? '',
          eventSource: 'download item',
          pageSpecialty: getPageSpecialty(episodeType)
        })
      }
    >
      {children}
    </a>
  );
}

export function ChapterDownloadItem({
  attachment,
  canDownload,
  episodeType
}: ChapterDownloadItemProps) {
  return (
    <ChapterDownloadItemWrapper
      attachment={attachment}
      canDownload={canDownload}
      episodeType={episodeType}
    >
      <Icon
        className="chapter-download-item__icon"
        name={getIconName(attachment.extension)}
      />
      <span>
        <span className="chapter-download-item__file-title">
          {attachment.title}
        </span>
        <span className="chapter-download-item__file-description">
          <span className="chapter-download-item__file-details">
            {formatBytes(attachment.fileSize)} -{' '}
            {getFileType(attachment.extension)}
          </span>
          {canDownload && (
            <span className="chapter-download-item__file-download-text">
              download
            </span>
          )}
        </span>
      </span>
    </ChapterDownloadItemWrapper>
  );
}
